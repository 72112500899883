body .pagination li{
    display: inline-block;
    margin: 0 0 0 -1px; 
    border-radius: 0;
    border: 1px solid #dee2e6;
    width: 35px;
    height: 33px;
    text-align: center;    
    transition: all 0.3s;
  }
  body .pagination li.true{
    background: #dee2e6;
    border-color: #dee2e6;
  }
  
  body .pagination li a {
    font-size: 16px;
    text-decoration: none;
    display: block;
    line-height: 33px;
    color: #007bff;
    font-weight: 500;
  }
  body .pagination li:hover{
    border-color: #3a3a3a; 
  }
  body .pagination{    
    text-align: center;
    overflow: hidden;
    margin-top: 20px;
    padding-left: 0;
    display: block;
    border-radius: 3px;
    float: right;
    margin-bottom: 20px;
  }
  body .pagination ul{
    padding-left: 0;
  }
  body .pagination li.current:hover,body .pagination li:hover{
    border-color: #dee2e6; 
  }
  body .pagination li.current:hover,body .pagination li:hover{
    background-color: #dee2e6
  }
  body .pagination li span{
    cursor: pointer;
    display: block;
    padding: 5px;
  }
  body .pagination li:first-child {
    margin-left: 0;
  }
  body .pagination li.current span{
    cursor: auto
  }
.map-wrapper {
    min-height: 320px;
}
.places-container {
    position: absolute;
    z-index: 98;
    background-color: #fff;
    max-height: 270px;
    overflow: auto;
    padding: 10px 20px;
    border: 1px solid #ced4da;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

ul.places-container li {
    list-style: none;
}
.order-detail-view .card {
    height: 100%;
}

.order-detail-view .main-block .col-6{
    padding: 10px;
}

.order-detail-view .card .card-header{
    background-color:#FFF;
    font-weight: bold;
}
.top {
    padding-top: 40px;
    /* padding-left: 13% !important; */
    /* padding-right: 13% !important */
}
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar .step0:before {
    font-family: FontAwesome;
    content: "\F10C";
    color: #fff
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after {
    left: -50%
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #651FFF
}

#progressbar li.inactive:before,
#progressbar li.inactive:after {
    background: #ff0000
}


#progressbar li.active:before {
    font-family: FontAwesome;
    content: "\F00C"
}

#progressbar li.inactive:before {
    font-family: FontAwesome;
    content: "\F00D"
}

.icon {
    width: 50px;
    height: 50px;
    /* margin-right: 10px */
}

.icon-content {
    padding-bottom: 20px
}

@media screen and (max-width: 992px) {
    .icon-content {
        width: 50%
    }
}
/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.notification-container .notification-error {
  /* background-color: #bd362f; */
  background-color: #fff;
  color: #bd362f;
}

.notification-container {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
}

.apikey .input-group i.mdi {
  font-size: 20px;
  margin: 7px;
  cursor: pointer;
}
