/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.notification-container .notification-error {
  /* background-color: #bd362f; */
  background-color: #fff;
  color: #bd362f;
}

.notification-container {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
}

.apikey .input-group i.mdi {
  font-size: 20px;
  margin: 7px;
  cursor: pointer;
}